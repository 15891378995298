<template>
  <div>
    <div v-if="view == 'table'">
      <CCard>
        <CCardHeader>
          <strong>Filtrar por </strong>
          <button
            type="button"
            class="btn-sm btn-info float-right"
            @click="mtd_send_register"
          >
            <i class="fas fa-plus"></i>&nbsp;Nuevo Acopio
          </button>
        </CCardHeader>
        <CCardBody>
          <!-- Para buscar por proyecto-->
          <CRow>
            <CCol sm="3">
              <CInput
                label="Documento del provedor"
                placeholder="12345678"
                v-model="filter.document"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                type="date"
                label="Fecha Inicio"
                v-model="filter.date_initial"
              >
              </CInput>
            </CCol>
            <CCol sm="3">
              <CInput
                type="date"
                label="Fecha Fin"
                v-model="filter.date_end"
                :min="filter.date_initial"
              >
              </CInput>
            </CCol>
            <CCol sm="2">
              <label>&nbsp;</label>
              <br />
              <CButton
                type="submit"
                color="primary"
                @click="mtd_search_filter()"
              >
                <i class="fa fa-search"></i> Buscar Acopios
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CRow>
        <CCol sm="12">
          <CTableWrapper
            :items="data"
            :fields="fields"
            hover
            :striped="true"
            :border="true"
            small
            fixed
            caption="Lista de Acopios no socio"
            icon="fas fa-dolly"
            btn_name="acopio"
            :size="'lg'"
            :button_new="false"
            :actions="true"
            :withActions="'10%'"
            :buttonEdit="true"
            :buttonDelete="true"
            :myButtons="myButtons"
            @mtd_view_pdf="mtd_view_pdf"
            @action_delete="mtd_delete"
            @action_edit="mtd_edit"
            @mtd_endosar="mtd_endosar"
          />
        </CCol>
      </CRow>
    </div>
    <!-- registro -->
    <div v-if="view == 'store'">
      <CRow v-if="bells != null">
        <CCol sm="12">
          <button
            type="button"
            class="btn-sm btn-info float-right"
            @click="mtd_back_to_table"
          >
            <i class="fas fa-arrow-left"></i>&nbsp;Regresar
          </button>
        </CCol>
        <br /><br />
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong> PROOVEDOR</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="2" md="2" sm="12" xs="12">
                  <CInput
                    label="Documento del provedor"
                    placeholder="12345678"
                    v-model="header.document"
                    v-on:keyup.enter="mtd_search_provider"
                  />
                </CCol>
                <CCol lg="6" md="6" sm="12" xs="12">
                  <CInput
                    label="Nombre del provedor"
                    placeholder="Nombre"
                    v-model="header.name"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="12" xs="12">
                  <CInput
                    label="Zona"
                    placeholder="Zona"
                    v-model="header.zone"
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>
              <strong> DETALLE DE ACOPIO</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Ticket"
                    placeholder="Ticket"
                    v-model="item_detail.ticket"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Humedad"
                    placeholder="Humedad"
                    v-model="item_detail.humedad"
                    @blur="mtd_out_focus"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Rendimiento Fisico(%)"
                    placeholder="Rendimiento Fisico(%)"
                    v-model="item_detail.rendimiento"
                    @blur="mtd_out_focus"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Taza"
                    placeholder="Taza"
                    v-model="item_detail.taza"
                    @blur="mtd_out_focus"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Núm. sacos"
                    placeholder="Núm. sacos"
                    v-model="item_detail.sacos"
                    @blur="mtd_out_focus"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Peso bruto"
                    placeholder="Peso bruto"
                    v-model="item_detail.peso_bruto"
                    @blur="mtd_out_focus"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <!-- calculados -->
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Descuento x humedad"
                    placeholder="Descuento x humedad"
                    :value="cp_descuentoxhumedad"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Tara sacos"
                    placeholder="Tara sacos"
                    :value="cp_tara"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Peso neto(kg)"
                    placeholder="Peso neto(kg)"
                    :value="cp_pesoneto"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="N° QQ"
                    placeholder="N° QQ"
                    :value="cp_qq"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Precio QQ"
                    placeholder="Precio QQ"
                    v-model="item_detail.precio_qq"
                    @blur="mtd_out_focus"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Precio uni."
                    placeholder="Precio uni."
                    :value="cp_uni"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Total Liq."
                    placeholder="Total liq."
                    :value="cp_liq"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="6" md="6" sm="12" xs="12">
                  <CInput
                    label="Detalle"
                    placeholder="Detalle"
                    v-model="item_detail.detail"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <label for="">&nbsp;</label>
                  <br />
                  <CButton
                    label=""
                    color="info"
                    v-c-tooltip.hover="{
                      content: 'Agregar item',
                    }"
                    :disabled="cp_button"
                    @click="mtd_add_item"
                  >
                    <i class="fa fa-plus"> </i> Agregar
                  </CButton>
                </CCol>
              </CRow>
              <br />
              <CRow v-if="details.length > 0">
                <CCol lg="12" md="12" sm="12" xs="12">
                  <table class="table table-bordered table-striped responsive">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Detalle</th>
                        <th>Peso neto</th>
                        <th>Liquidación</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in details" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.detail }}</td>
                        <td>{{ item.peso_neto }}</td>
                        <td>{{ item.total }}</td>
                        <td>
                          <CButton
                            size="sm"
                            color="danger"
                            v-c-tooltip.hover="{
                              content: 'Eliminar',
                            }"
                            @click="mtd_delitemcarrito(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2" class="text-right">
                          <strong>TOTALES:</strong>
                        </td>
                        <td>{{ cp_total_neto }}</td>
                        <td>{{ cp_total_liq }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </CCol>

                <CCol class="text-center" lg="12" md="12" sm="12" xs="12">
                  <CButton
                    size="lg"
                    color="danger"
                    v-c-tooltip.hover="{
                      content: 'Vaciar carrito',
                    }"
                    @click="mtd_vaciar_carrtito"
                  >
                    <i class="fas fa-trash"></i> Vaciar carrito
                  </CButton>
                  &nbsp;&nbsp;
                  <CButton
                    size="lg"
                    color="info"
                    v-c-tooltip.hover="{
                      content: 'Guardar',
                    }"
                    @click="mtd_save_acopio"
                  >
                    <i class="fas fa-save"></i> Guardar Acopio
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol col="12">
          <CCard>
            <CCardHeader> CAMPAÑA NO INICIADA </CCardHeader>
            <CCardBody>
              COMUNIQUESE CON EL SUPERADMINISTRADOR PARA INICIAR UNA CAMPAÑA Y
              PODER CONTINUAR.
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <!-- registro -->
    <div v-if="view == 'endosar'">
      <CRow v-if="bells != null">
        <CCol sm="12">
          <button size="lg" type="button" class="btn-sm btn-primary float-left">
            Total neto: {{ parseFloat(endosar.neto).toFixed(2) }} kg
          </button>
          <button
            size="lg"
            type="button"
            class="btn-sm btn-primary float-left ml-3"
          >
            Total sacos: {{ parseFloat(endosar.sacos).toFixed(2) }}
          </button>
          <button
            size="lg"
            type="button"
            class="btn-sm btn-primary float-left ml-3"
          >
            Total liquidación: {{ parseFloat(endosar.liquidacion).toFixed(2) }}
          </button>

          <button
            size="lg"
            type="button"
            class="btn-sm btn-info float-left ml-3"
            @click="mtd_open_escojido"
          >
            Total acopios escogidos: {{ endosar.data.length }}
          </button>

          <button
            size="lg"
            type="button"
            class="btn-sm btn-warning float-left ml-3"
          >
            Falta neto: {{ parseFloat(endosar.falta_neto).toFixed(2) }} kg
          </button>
          <button
            size="lg"
            type="button"
            class="btn-sm btn-warning float-left ml-3"
          >
            Falta sacos: {{ parseFloat(endosar.falta_saco).toFixed(2) }}
          </button>
          <button
            size="lg"
            type="button"
            class="btn-sm btn-warning float-left ml-3"
          >
            Falta liquidación: {{ parseFloat(endosar.falta_liq).toFixed(2) }}
          </button>

          <button
            type="button"
            class="btn-sm btn-info float-right"
            @click="mtd_back_to_table"
          >
            <i class="fas fa-arrow-left"></i>&nbsp;Regresar
          </button>
        </CCol>
        <br /><br />
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong> PROOVEDOR</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="2" md="2" sm="12" xs="12">
                  <CInput
                    label="Documento del provedor"
                    placeholder="12345678"
                    v-model="item_header.document"
                    v-on:keyup.enter="mtd_search_supplier"
                  />
                </CCol>
                <CCol lg="6" md="6" sm="12" xs="12">
                  <CInput
                    label="Nombre del provedor"
                    placeholder="Nombre"
                    v-model="item_header.name"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="12" xs="12">
                  <CInput
                    label="Base"
                    placeholder="Base"
                    v-model="item_header.base"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="12" xs="12">
                  <CInput
                    label="Ruc del provedor"
                    placeholder="Ruc"
                    v-model="item_header.ruc"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="4" md="4" sm="12" xs="12">
                  <label for="">Elegir serie</label>
                  <v-select
                    :options="data_serie"
                    v-model="item_header.serie"
                    placeholder="Seleccione una serie"
                  />
                </CCol>
                <CCol
                  lg="6"
                  md="6"
                  sm="12"
                  xs="12"
                  v-if="item_header.ruc != 'NO TIENE'"
                >
                  <label for="">Elegir Alias</label>
                  <v-select
                    :options="data_alias"
                    v-model="item_header.alias"
                    placeholder="Seleccione un alias"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>
              <strong> DETALLE DE ACOPIO</strong>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Ticket"
                    placeholder="Ticket"
                    v-model="item_detail_endosar.ticket"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Muestra(gr)"
                    placeholder="Muestra(gr)"
                    @blur="mtd_out_focus_endosar"
                    v-model="item_detail_endosar.muestra"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Cascara(%)"
                    placeholder="Cascara(%)"
                    @blur="mtd_out_focus_endosar"
                    v-model="item_detail_endosar.cascara"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Peso inicial"
                    placeholder="Peso inicial"
                    v-model="item_detail_endosar.peso_inicial"
                    @blur="mtd_out_focus_endosar"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Rendimiento Fisico(%)"
                    placeholder="Rendimiento Fisico(%)"
                    v-model="item_detail_endosar.rendimiento"
                    @blur="mtd_out_focus_endosar"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Humedad"
                    placeholder="Humedad"
                    v-model="item_detail_endosar.humedad"
                    @blur="mtd_out_focus_endosar"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Descuento x humedad"
                    placeholder="Descuento x humedad"
                    :value="cp_descuentoxhumedad_endosar"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Peso bruto"
                    placeholder="Peso bruto"
                    :value="cp_pesobruto_endosar"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Núm. sacos"
                    placeholder="Núm. sacos"
                    v-model="item_detail_endosar.sacos"
                    @blur="mtd_out_focus_endosar"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Tara saco"
                    placeholder="Tara saco"
                    :value="cp_tarasacos_endosar"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Taza"
                    placeholder="Taza"
                    v-model="item_detail_endosar.taza"
                    @blur="mtd_out_focus_endosar"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Peso neto"
                    placeholder="Peso neto"
                    :value="cp_pesoneto_endosar"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Neto num QQ"
                    placeholder="Neto num QQ"
                    :value="cp_netoqq_endosar"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Precio QQ"
                    placeholder="Precio QQ"
                    v-model="item_detail_endosar.precio_qq"
                    @blur="mtd_out_focus_endosar"
                    v-on:keypress="isNumber($event)"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Precio x KG"
                    placeholder="Precio x KG"
                    :value="cp_preciokg_endosar"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <CInput
                    label="Precio liq."
                    placeholder="Precio liq."
                    :value="cp_precioliq_endosar"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="4" md="4" sm="6" xs="12">
                  <label for="">Elegir detalle</label>
                  <v-select
                    :options="data_product_entry"
                    v-model="item_detail_endosar.product_entry_id"
                    placeholder="Seleccione un detalle"
                  />
                </CCol>
                <CCol lg="12" md="12" sm="12" xs="12">
                  <CTextarea
                    label="Observación"
                    placeholder="Observación"
                    v-model="item_detail_endosar.obs"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <label for="">&nbsp;</label>
                  <br />
                  <CButton
                    label=""
                    color="success"
                    v-c-tooltip.hover="{
                      content: 'Verificar estimado',
                    }"
                    :disabled="false"
                    @click="mtd_verif_neto"
                  >
                    VERIFICAR ESTIMADO
                  </CButton>
                </CCol>
                <CCol lg="4" md="4" sm="6" xs="12">
                  <label for="">Elegir Certificado</label>
                  <v-select
                    multiple
                    :options="data_certifications_filter"
                    v-model="item_detail_endosar.certification"
                    placeholder="Seleccione certificados(s)"
                  />
                </CCol>
                <CCol
                  lg="4"
                  md="4"
                  sm="6"
                  xs="12"
                  v-if="item_detail_endosar.verif_certificado == true"
                >
                  <CInput
                    label="Certificado"
                    placeholder=""
                    value="ORGÁNICO"
                    :disabled="true"
                  />
                </CCol>
                <CCol lg="2" md="2" sm="6" xs="12">
                  <label for="">&nbsp;</label>
                  <br />
                  <CButton
                    label=""
                    color="info"
                    v-c-tooltip.hover="{
                      content: 'Agregar item',
                    }"
                    :disabled="!verif_btn"
                    @click="mtd_add_item_acopio"
                  >
                    <i class="fa fa-plus"> </i> Agregar
                  </CButton>
                </CCol>
              </CRow>
              <br />
              <CRow v-if="details_endosar.length > 0">
                <CCol lg="12" md="12" sm="12" xs="12">
                  <table class="table table-bordered table-striped responsive">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Detalle</th>
                        <th>Sacos</th>
                        <th>Peso neto</th>
                        <th>Liquidación</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in details_endosar" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.product_entry_id.label }}</td>
                        <td>{{ item.sacos }}</td>
                        <td>{{ item.peso_neto }}</td>
                        <td>{{ item.precio_liq }}</td>
                        <td>
                          <CButton
                            size="sm"
                            color="danger"
                            v-c-tooltip.hover="{
                              content: 'Eliminar',
                            }"
                            @click="mtd_delitemcarrito_endosar(index)"
                          >
                            <i class="fas fa-trash"></i>
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="2" class="text-right">
                          <strong>TOTALES:</strong>
                        </td>
                        <td>{{ cp_total_saco_endosar }}</td>
                        <td>{{ cp_total_neto_endosar }}</td>
                        <td>{{ cp_total_liq_endosar }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </CCol>

                <CCol class="text-center" lg="12" md="12" sm="12" xs="12">
                  <CButton
                    color="danger"
                    v-c-tooltip.hover="{
                      content: 'Vaciar carrito',
                    }"
                    @click="mtd_vaciar_carrtito_endosar"
                  >
                    <i class="fas fa-trash"></i> Vaciar carrito
                  </CButton>
                  &nbsp;&nbsp;
                  <CButton
                    color="info"
                    v-c-tooltip.hover="{
                      content: 'Elejig Acopio',
                    }"
                    @click="mtd_elegir_endose"
                  >
                    <i class="fas fa-save"></i> Elejir Acopio
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <!-- modal delete -->
    <cModalDelete
      title="Borrar Acopio"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

    <!-- modal ver acopios escojidos cp_button_save -->
    <CModalForm
      :size="'xl'"
      :title="modal_escojido.title"
      :button="false"
      :show.sync="modal_escojido.modal_form"
      @mtd_action="mtd_action_escojido"
    >
      <CRow>
        <CCol lg="12" md="12" sm="12" xs="12">
          <table class="table table-bordered table-striped responsive">
            <thead>
              <tr>
                <th>Proveedor</th>
                <th>Detalle</th>
                <th>Sacos</th>
                <th>Peso neto</th>
                <th>Liquidación</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in temp_body" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.prd }}</td>
                  <td>{{ item.saco }}</td>
                  <td>{{ item.neto }}</td>
                  <td>{{ item.liq }}</td>
                  <td><button class='btn-sm btn-danger' @click="mtd_del_det_escojido(item.index,item.ind)"> <i class='fa fa-trash'> </i> </button> </td>
                </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2" class="text-right"><strong>TOTALES:</strong></td>
                <td>{{cp_table_saco}}</td>
                <td>{{cp_table_neto}}</td>
                <td>{{cp_table_liq}}</td>
              </tr>
            </tfoot>
          </table>
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>

<script>
const fields = [
  { key: "id_detalle_acopio", label: "Id", _style: "width:3%" },
  { key: "date", label: "Fecha", _style: "width:5%" },
  { key: "name_acopio", label: "Acopio", _style: "width:20%;" },
  { key: "document", label: "Dni", _style: "width:20%;" },
  { key: "supplier", label: "Proveedor", _style: "width:20%;" },
  { key: "detalle", label: "Detalle", _style: "width:20%;" },
  { key: "serie", label: "Serie", _style: "width:5%;" },
  { key: "ticket", label: "TK", _style: "width:5%;" },
  { key: "humedad", label: "H%", _style: "width:5%;" },
  { key: "rendimiento", label: "Rend.", _style: "width:5%;" },
  { key: "taza", label: "Taza", _style: "width:12%;" },
  { key: "num_sacos", label: "N° S", _style: "width:12%;" },
  { key: "peso_bruto", label: "P. Bruto", _style: "width:12%;" },
  { key: "descuento", label: "Dst H.", _style: "width:12%;" },
  { key: "tara_saco", label: "Tara S", _style: "width:12%;" },
  { key: "peso_neto", label: "P. Neto", _style: "width:12%;" },
  { key: "neto_num_qq", label: "N° QQ", _style: "width:12%;" },
  { key: "precio_qq", label: "Precio QQ", _style: "width:12%;" },
  { key: "precio_uni", label: "Precio uni.", _style: "width:12%;" },
  { key: "total", label: "Total", _style: "width:12%;" },
  { key: "status", label: "Estado", _style: "width:12%;" },
];

import { mapActions } from "vuex";
import { bus } from "../../main";

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: { CTableWrapper, cModalDelete, vSelect, CModalForm },
  data() {
    return {
      prefix: "provideracopio",
      fields,
      data: [],
      filter: {
        document: null,
        date_initial: "",
        date_end: "",
      },
      view: "table",
      bells: null,
      header: {
        supplier_id: "",
        document: "",
        name: "",
        zone: "",
      },
      details: [],
      item_detail: {
        ticket: "",
        humedad: "",
        rendimiento: "",
        taza: "",
        sacos: "",
        peso_bruto: "",
        dsctxh: "",
        tara_sacos: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_uni: "", // truncar 5
        total: "",
        detail: "",
      },
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Ticket PDF",
          action: "mtd_view_pdf",
          icon: "fas fa-file-alt",
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Endosar",
          action: "mtd_endosar",
          icon: "fas fa-share",
          show: {
            1: true,
            2: false,
          },
        },
      ],
      modal_delete: {
        boo: false,
        item: [],
      },
      /** endosar */
      endosar: {
        id_acopio: "",
        bell_id:"",
        sacos: "",
        neto: "",
        liquidacion: "",
        data: [],
        falta_saco: 0,
        falt_neto: 0,
        falta_liq: 0,
      },
      item_endose: [], // contiede header y header.data => detalle
      item_header: {
        document: "",
        supplier_id: "",
        name: "",
        ruc: "NO TIENE",
        base: "",
        alias: [],
        serie: [],
        detail: [],
      },
      item_detail_endosar: {
        ticket: "",
        muestra: "",
        cascara: "",
        peso_inicial: "",
        dsctxh: "",
        peso_bruto: "",
        r: "",
        humedad: "",
        sacos: "",
        tara_sacos: "",
        taza: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_kg: "", // truncar 5
        precio_liq: "",
        product_entry_id: [],
        verif_certificado: false,
        certification: [],
        obs: "",
      },
      details_endosar: [],
      data_serie: [{ value: "0002", label: "0002" }],
      data_alias: [],
      data_certifications: [],
      data_certifications_filter: [],
      data_product_entry: [],
      modal_escojido: {
        title: "DETALLE ACOPIOS ESCOJIDOS",
        modal_form: false,
      },
      temp_body: [],
      verif_btn : false
    };
  },
  computed: {
    cp_descuentoxhumedad: function () {
      if (this.item_detail.humedad.length >1) {
          let bruto =
          this.item_detail.peso_bruto > 0
            ? parseFloat(this.item_detail.peso_bruto).toFixed(2)
            : 0.0;
        let hume =
          this.item_detail.humedad > 0
            ? parseFloat(this.item_detail.humedad).toFixed(2)
            : 0.0;
        let op1 = parseFloat(bruto / 55.2).toFixed(2);
        let op2 = parseFloat(hume - 12);
        let res = parseFloat(op1 * op2 * 0.63).toFixed(2);
        this.item_detail.dsctxh = res;
        return res;  
      }
      
    },
    cp_tara: function () {
      let sacos =
        this.item_detail.sacos > 0
          ? parseFloat(this.item_detail.sacos).toFixed(2)
          : 0.0;
      let res = parseFloat(sacos * 0.25).toFixed(2);
      this.item_detail.tara_sacos = res;
      return res;
    },
    cp_pesoneto: function () {
      let bruto =
        this.item_detail.peso_bruto > 0
          ? parseFloat(this.item_detail.peso_bruto).toFixed(2)
          : 0.0;
      let humedad =
        this.item_detail.dsctxh > 0
          ? parseFloat(this.item_detail.dsctxh).toFixed(2)
          : 0.0;
      let tara =
        this.item_detail.tara_sacos > 0
          ? parseFloat(this.item_detail.tara_sacos).toFixed(2)
          : 0.0;
      let res = parseFloat(bruto - humedad - tara).toString();
      if (res <= 0) {
        this.peso_neto = 0;
        return 0;
      }
      let ar = res.split(".");
      let new_decimal = ar.length==2? ar[1].substring(0, 2):0;
      let new_res = ar[0] + "." + new_decimal;
      this.item_detail.peso_neto = parseFloat(new_res).toFixed(2);
      return this.item_detail.peso_neto;
      
    },
    cp_qq: function () {
      let neto =
        this.item_detail.peso_neto > 0
          ? parseFloat(this.item_detail.peso_neto).toFixed(2)
          : 0.0;
      let res = parseFloat(neto / 55.2).toString();
      if (res <= 0) {
        this.peso_neto = 0;
        return 0;
      }
      let ar = res.split(".");
      let new_decimal = ar[1].substring(0, 2);
      let new_res = ar[0] + "." + new_decimal;
      this.item_detail.neto_num_qq = parseFloat(new_res).toFixed(2);
      return this.item_detail.neto_num_qq;
    },
    cp_liq: function () {
      let precio_qq =
        this.item_detail.precio_qq > 0
          ? parseFloat(this.item_detail.precio_qq).toFixed(2)
          : 0.0;
      let num_qq =
        this.item_detail.neto_num_qq > 0
          ? parseFloat(this.item_detail.neto_num_qq).toFixed(2)
          : 0.0;
      this.item_detail.total = parseFloat(precio_qq * num_qq).toFixed(2);
      return this.item_detail.total;
    },
    cp_uni: function () {
      let total =
        this.item_detail.total > 0
          ? parseFloat(this.item_detail.total)
          : 0.0;
      let neto =
        this.item_detail.peso_neto > 0
          ? parseFloat(this.item_detail.peso_neto)
          : 0.0;
      this.item_detail.precio_uni =
        neto > 0 ? parseFloat(total / neto).toFixed(7) : 0;
      return this.item_detail.precio_uni;
    },
    cp_button() {
      return this.item_detail.detail.length > 0 ? false : true;
    },
    cp_total_neto: function () {
      let tot = 0;
      if (this.details.length > 0) {
        this.details.forEach((element) => {
          tot += element.peso_neto;
        });
        return parseFloat(tot);
      } else {
        return 0;
      }
    },
    cp_total_liq: function () {
      let tot = 0;
      if (this.details.length > 0) {
        this.details.forEach((element) => {
          tot += element.total;
        });
        return parseFloat(tot);
      } else {
        return 0;
      }
    },
    /** endosar */
    cp_descuentoxhumedad_endosar() {
      if (
        this.item_detail_endosar.humedad.length > 0 &&
        this.item_detail_endosar.peso_inicial.length > 0
      ) {
        let p1 = parseFloat(this.item_detail_endosar.humedad) - 12;
        let p2 = parseFloat(this.item_detail_endosar.peso_inicial) / 55.2;
        let res = p1 * 0.63 * p2;
        this.item_detail_endosar.dsctxh = parseFloat(res).toFixed(2);
        return parseFloat(res).toFixed(2);
      } else {
        this.item_detail_endosar.dsctxh = 0;
        return 0.0;
      }
    },
    cp_pesobruto_endosar() {
      if (
        parseFloat(this.item_detail_endosar.dsctxh) >= 0 &&
        this.item_detail_endosar.peso_inicial.length > 0
      ) {
        let peso = parseFloat(this.item_detail_endosar.peso_inicial);
        let dsctxh = parseFloat(this.item_detail_endosar.dsctxh);
        let res = parseFloat(peso - dsctxh).toFixed(2);
        this.item_detail_endosar.peso_bruto = res;
        return res;
      } else {
        this.item_detail_endosar.peso_bruto = 0;
        return 0.0;
      }
    },
    cp_tarasacos_endosar() {
      if (this.item_detail_endosar.sacos.length > 0) {
        let tar = parseFloat(this.item_detail_endosar.sacos) * 0.25;
        this.item_detail_endosar.tara_sacos = parseFloat(tar).toFixed(2);
        return this.item_detail_endosar.tara_sacos;
      } else {
        this.item_detail_endosar.tara_sacos = 0.0;
        return 0.0;
      }
    },
    cp_pesoneto_endosar() {
      if (
        this.item_detail_endosar.peso_bruto > 0 &&
        this.item_detail_endosar.tara_sacos > 0
        // && this.item_detail_endosar.dsctxh >= 0
      ) {
        let res = parseFloat(
          this.item_detail_endosar.peso_bruto -
            this.item_detail_endosar.tara_sacos
          // - this.item_detail_endosar.dsctxh
        );
        this.item_detail_endosar.peso_neto = res;
        return res;
      } else {
        return 0;
      }
    },
    cp_netoqq_endosar() {
      if (this.item_detail_endosar.peso_neto > 0) {
        let res = parseFloat(
          this.item_detail_endosar.peso_neto / 55.2
        ).toString();
        let ar = res.split(".");
        let new_decimal = ar[1].substring(0, 2);
        let new_res = ar[0] + "." + new_decimal;
        this.item_detail_endosar.neto_num_qq = parseFloat(new_res);
        return parseFloat(new_res);
      } else {
        this.item_detail_endosar.neto_num_qq = 0;
        return 0;
      }
    },
    cp_precioliq_endosar() {
      if (
        this.item_detail_endosar.neto_num_qq > 0 &&
        this.item_detail_endosar.precio_qq.length > 0
      ) {
        let res = parseFloat(
          this.item_detail_endosar.neto_num_qq *
            this.item_detail_endosar.precio_qq
        ).toFixed(2);
        this.item_detail_endosar.precio_liq = res;
        return res;
      } else {
        this.item_detail_endosar.precio_liq = 0;
        return 0;
      }
    },
    cp_preciokg_endosar() {
      if (
        this.item_detail_endosar.precio_liq > 0 &&
        this.item_detail_endosar.peso_neto > 0
      ) {
        let res = parseFloat(
          this.item_detail_endosar.precio_liq /
            this.item_detail_endosar.peso_neto
        ).toString();
        let ar = res.split(".");
        let new_decimal = ar[1].substring(0, 7);
        let new_res = ar[0] + "." + new_decimal;
        this.item_detail_endosar.precio_kg = new_res;
        return new_res;
      } else {
        this.item_detail_endosar.precio_liq = 0;
        return 0;
      }
    },
    cp_total_saco_endosar: function () {
      let tot = 0;
      if (this.details_endosar.length > 0) {
        this.details_endosar.forEach((element) => {
          tot += parseFloat(element.sacos);
        });
        return parseFloat(tot).toFixed(2);
      } else {
        return 0;
      }
    },
    cp_total_neto_endosar: function () {
      let tot = 0;
      if (this.details_endosar.length > 0) {
        this.details_endosar.forEach((element) => {
          tot += parseFloat(element.peso_neto);
        });
        return parseFloat(tot).toFixed(2);
      } else {
        return 0;
      }
    },
    cp_total_liq_endosar: function () {
      let tot = 0;
      if (this.details_endosar.length > 0) {
        this.details_endosar.forEach((element) => {
          tot += parseFloat(element.precio_liq);
        });
        return parseFloat(tot).toFixed(2);
      } else {
        return 0;
      }
    },
    cp_button_save: function(){
      if (parseFloat(this.endosar.falta_neto) == 0 && parseFloat(this.endosar.falta_saco) == 0 && parseFloat(this.endosar.falta_liq) == 0) return false;
      return true;
    },
    cp_table_neto: function(){
      let to =0;
      this.temp_body.forEach(element => {
        to += parseFloat(element.neto);
      });
      return parseFloat(to).toFixed(2);
    },
    cp_table_saco: function(){
      let to =0;
      this.temp_body.forEach(element => {
        to += parseFloat(element.saco);
      });
      return parseFloat(to).toFixed(2);
    },
    cp_table_liq: function(){
      let to =0;
      this.temp_body.forEach(element => {
        to += parseFloat(element.liq);
      });
      return parseFloat(to).toFixed(2);
    },
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    // this.filter.date_initial = fecha;
    this.filter.date_end = fecha;

    let temp = new Date();
    temp.setDate(temp.getDate() - 5);
    this.filter.date_initial =
      temp.getFullYear() +
      "-" +
      ("0" + (temp.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + temp.getDate()).slice(-2);
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.bells = response.bells;
          this.mtd_search_filter();
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_search_filter: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/searchFilter",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          if (response.state == 0) {
            this.data = response.data.map((item, order) => {
              return { ...item, order };
            });
          } else {
            this.data = [];
            bus.$emit("alert", {
              color: "danger",
              message: "EL DOCUMENTO NO EXISTE",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_send_register: function () {
      this.view = "store";
    },
    mtd_back_to_table: function () {
      this.view = "table";
      this.mtd_search_filter();
    },
    mtd_search_provider: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.header.document +
          "/search_provider",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response) {
            bus.$emit("alert", {
              color: "success",
              message: "DOCUMENTO ENCONTRADO",
            });
            this.header.supplier_id = response.id;
            this.header.name = response.last_name + " " + response.name;
            this.header.zone = response.zone;
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: "EL DOCUMENTO NO EXISTE",
            });
            this.header.document = "";
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_out_focus: function () {
      this.item_detail.humedad =
        this.item_detail.humedad > 0
          ? parseFloat(this.item_detail.humedad).toFixed(2)
          : 0.0;
      this.item_detail.rendimiento =
        this.item_detail.rendimiento > 0
          ? parseFloat(this.item_detail.rendimiento).toFixed(2)
          : 0.0;
      this.item_detail.peso_bruto =
        this.item_detail.peso_bruto > 0
          ? parseFloat(this.item_detail.peso_bruto).toFixed(2)
          : 0.0;
      this.item_detail.sacos =
        this.item_detail.sacos > 0
          ? parseFloat(this.item_detail.sacos).toFixed(2)
          : 0.0;
      this.item_detail.taza =
        this.item_detail.taza > 0
          ? parseFloat(this.item_detail.taza).toFixed(2)
          : 0.0;
      this.item_detail.precio_qq =
        this.item_detail.precio_qq > 0
          ? parseFloat(this.item_detail.precio_qq).toFixed(2)
          : 0.0;
    },
    mtd_add_item: function () {
      this.details.push(this.item_detail);
      this.item_detail = {
        ticket: "",
        humedad: "",
        rendimiento: "",
        taza: "",
        sacos: "",
        peso_bruto: "",
        dsctxh: "",
        tara_sacos: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_uni: "", // truncar 5
        total: "",
        detail: "",
      };
    },
    mtd_delitemcarrito: function (pos) {
      let temp = [];
      this.details.forEach((element, index) => {
        if (index !== pos) {
          temp.push(element);
        }
      });
      this.details = temp;
    },
    mtd_vaciar_carrtito: function () {
      this.details = [];
    },
    mtd_save_acopio: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/store",
        token: this.$store.getters.get__token,
        params: {
          header: this.header,
          details: this.details,
          bell_id: this.bells.id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            this.header = {
              supplier_id: "",
              document: "",
              name: "",
              zone: "",
            };
            this.details = [];
            this.item_detail = {
              ticket: "",
              humedad: "",
              rendimiento: "",
              taza: "",
              sacos: "",
              peso_bruto: "",
              dsctxh: "",
              tara_sacos: "",
              peso_neto: "", // truncar
              neto_num_qq: "", // truncar
              precio_qq: "",
              precio_uni: "", // truncar 5
              total: "",
              detail: "",
            };
            bus.$emit("alert", {
              color: "success",
              message: "ACOPIO GUARDADO CORRECTAMENTE",
            });
            window.open("/assets/acopio/" + response.uuid + ".pdf", "_blank");
            this.mtd_back_to_table();
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.exception,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** endosar */
    mtd_endosar: function (item) {
      this.view = "endosar";
      this.endosar.bell_id = this.bells.id;
      this.endosar.id_acopio = item.Id;
      this.endosar.sacos = parseFloat(item.num_sacos).toFixed(2);
      this.endosar.neto = parseFloat(item.peso_neto).toFixed(2);
      this.endosar.liquidacion = parseFloat(item.total).toFixed(2);
      this.endosar.falta_saco = parseFloat(item.num_sacos).toFixed(2);
      this.endosar.falta_neto = parseFloat(item.peso_neto).toFixed(2);
      this.endosar.falta_liq = parseFloat(item.total).toFixed(2);
      this.mtd_getdata_endosar();
    },
    mtd_getdata_endosar: function () {
      this.get({
        url: this.$store.getters.get__url + "/acopio",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.bells = response.bells;
          this.data_alias = response.alias;
          this.data_product_entry = response.data_product_entry;
          this.data_certifications = response.data_certifications;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_search_supplier: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/acopio/" +
          this.item_header.document +
          "/search_supplier",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response) {
            bus.$emit("alert", {
              color: "success",
              message: "DOCUMENTO ENCONTRADO",
            });
            this.item_header.supplier_id = response.id;
            this.item_header.name = response.last_name + " " + response.name;
            this.item_header.ruc =
              response.ruc == null ? "NO TIENE" : response.ruc;
            this.item_header.base = response.base;
            /** certification */
            let cert = response.certification;
            let ar = cert.split(",");
            let temp = [];
            for (let index = 1; index < ar.length; index++) {
              const id_cer = ar[index];
              this.data_certifications.forEach((element) => {
                if (element.value == id_cer) {
                  temp.push(element);
                }
              });
            }
            this.data_certifications_filter = temp;
            // log
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: "EL DOCUMENTO NO EXISTE",
            });
            this.item_header.document = "";
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_out_focus_endosar: function () {
      this.item_detail_endosar.muestra =
        this.item_detail_endosar.muestra > 0
          ? parseFloat(this.item_detail_endosar.muestra).toFixed(2)
          : 0;
      this.item_detail_endosar.cascara =
        this.item_detail_endosar.cascara > 0
          ? parseFloat(this.item_detail_endosar.cascara).toFixed(2)
          : 0;
      this.item_detail_endosar.peso_inicial =
        this.item_detail_endosar.peso_inicial > 0
          ? parseFloat(this.item_detail_endosar.peso_inicial).toFixed(2)
          : 0;
      this.item_detail_endosar.rendimiento =
        this.item_detail_endosar.rendimiento > 0
          ? parseFloat(this.item_detail_endosar.rendimiento).toFixed(2)
          : 0.0;
      this.item_detail_endosar.humedad =
        this.item_detail_endosar.humedad > 0
          ? parseFloat(this.item_detail_endosar.humedad).toFixed(2)
          : 0.0;
      this.item_detail_endosar.sacos =
        this.item_detail_endosar.sacos > 0
          ? parseFloat(this.item_detail_endosar.sacos).toFixed(2)
          : 0.0;
      this.item_detail_endosar.taza =
        this.item_detail_endosar.taza > 0
          ? parseFloat(this.item_detail_endosar.taza).toFixed(2)
          : 0.0;
      this.item_detail_endosar.precio_qq =
        this.item_detail_endosar.precio_qq > 0
          ? parseFloat(this.item_detail_endosar.precio_qq).toFixed(2)
          : 0.0;
    },
    mtd_verif_neto: function () {
      let sum_carrito = 0;
      this.details.forEach((element) => {
        sum_carrito += element.peso_neto;
      });
      this.post({
        url: this.$store.getters.get__url + "/acopio/verif",
        token: this.$store.getters.get__token,
        params: {
          supplier_id: this.item_header.supplier_id,
          peso_neto: this.item_detail_endosar.peso_neto,
          bell_id: this.bells.id,
          sum_carrito: sum_carrito,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            // this.details.push(this.item_detail_endosar);
            bus.$emit("alert", {
              color: "success",
              message: "PESO VERIFICADO CORRECTAMENTE",
            });
            this.item_detail_endosar.verif_certificado = true;
            this.verif_btn = true;
          } else {
            this.item_detail_endosar.verif_certificado = false;
            this.verif_btn = false;
            bus.$emit("alert", {
              color: "warning",
              message: "EL PESO EXCEDE AL ESTIMADO POR EL PROVEEDOR",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_add_item_acopio: function () {
      this.details_endosar.push(this.item_detail_endosar);
      this.item_detail_endosar = {
        ticket: "",
        muestra: "",
        cascara: "",
        peso_inicial: "",
        dsctxh: "",
        peso_bruto: "",
        r: "",
        humedad: "",
        sacos: "",
        tara_sacos: "",
        taza: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_kg: "", // truncar 5
        precio_liq: "",
        product_entry_id: [],
        verif_certificado: false,
        certification: [],
        obs: "",
      };
    },
    mtd_delitemcarrito_endosar: function (pos) {
      let temp = [];
      this.details_endosar.forEach((element, index) => {
        if (index !== pos) {
          temp.push(element);
        }
      });
      this.details_endosar = temp;
    },
    mtd_vaciar_carrtito_endosar: function () {
      this.details_endosar = [];
    },
    mtd_elegir_endose: function () {
      let neto = 0,
        saco = 0,
        liq = 0;
      let data = this.endosar.data;
      data.forEach((element) => {
        let det = element.detail;
        det.forEach((elem) => {
          neto += parseFloat(elem.peso_neto);
          saco += parseFloat(elem.sacos);
          liq += parseFloat(elem.precio_liq);
        });
      });
      /** totales del detalle */
      let neto_det = 0,
        saco_det = 0,
        liq_det = 0;
      this.details_endosar.forEach((element) => {
        neto_det += parseFloat(element.peso_neto);
        saco_det += parseFloat(element.sacos);
        liq_det += parseFloat(element.precio_liq);
      });
      neto = parseFloat(neto) + parseFloat(neto_det);
      saco = parseFloat(saco) + parseFloat(saco_det);
      liq = parseFloat(liq) + parseFloat(liq_det);
      if (
        parseFloat(neto).toFixed(2) <= parseFloat(this.endosar.neto) &&
        parseFloat(saco).toFixed(2) <= parseFloat(this.endosar.sacos) &&
        parseFloat(liq).toFixed(2) <= parseFloat(this.endosar.liquidacion)
      ) {
        this.item_header.detail = this.details_endosar;
        this.endosar.data.push(this.item_header);
        this.item_detail_endosar = {
          ticket: "",
          muestra: "",
          cascara: "",
          peso_inicial: "",
          dsctxh: "",
          peso_bruto: "",
          r: "",
          humedad: "",
          sacos: "",
          tara_sacos: "",
          taza: "",
          peso_neto: "", // truncar
          neto_num_qq: "", // truncar
          precio_qq: "",
          precio_kg: "", // truncar 5
          precio_liq: "",
          product_entry_id: [],
          verif_certificado: false,
          certification: [],
          obs: "",
        };
        this.details_endosar = [],
        this.item_header = {
            document: "",
            supplier_id: "",
            name: "",
            ruc: "NO TIENE",
            base: "",
            alias: [],
            serie: [],
            detail: [],
        };
        bus.$emit("alert", {
          color: "success",
          message: "ACOPIO ESCOJIDO CORRECTAMENTE",
        });
        this.endosar.falta_neto = parseFloat(this.endosar.falta_neto - neto).toFixed(2);
        this.endosar.falta_saco = parseFloat(this.endosar.falta_saco - saco).toFixed(2);
        this.endosar.falta_liq = parseFloat(this.endosar.falta_liq - liq).toFixed(2);
      } else {
        bus.$emit("alert", {
          color: "warning",
          message:
            "LOS MONTOS DEL PESO NETO, SACOS Y LIQUIDADCIÓN NO PUEDEN SER MAYORES.",
        });
      }
    },
    mtd_open_escojido: function () {
      this.temp_body=[];
      let data = this.endosar.data;
      data.forEach((element, index) => {
        let det = element.detail;
        det.forEach((ele, ind) => {
          this.temp_body.push({
            name:element.name,
            prd: ele.product_entry_id.label,
            saco: ele.sacos,
            neto: ele.peso_neto,
            liq: ele.precio_liq,
            index:index,
            ind: ind
          });
        });
      });
      this.modal_escojido.modal_form = true;
    },
    mtd_del_det_escojido: function (pos1, pos2) {
      let temp = [];
      this.endosar.data.forEach((element,index) => {
        if (index == pos1) {
          let temp_det = [];
          element.detail.forEach((ele,ind) => {
            if (ind != pos2) {
              temp_det.push(ele);
            }
          });
          element.detail=temp_det;
          temp.push(element);
        }else{
          temp.push(element);
        }
      });
      this.endosar.data = temp;
      this.modal_escojido.modal_form = false;
      let temoGlobal = [];
      this.endosar.data.forEach((element,index) => {
          if(element.detail.length > 0) temoGlobal.push(element);
      });
      this.endosar.data = temoGlobal;
      this.mtd_open_escojido();
    },
    mtd_action_escojido: function () {
      this.post({
        url: this.$store.getters.get__url + "/provideracopio/endose/store",
        token: this.$store.getters.get__token,
        params: this.endosar,
      })
        .then((response) => {
          if (response.state == 0) {
            let ar = response.dataUuid;
            for (let index = 0; index < ar.length; index++) {
              window.open("/assets/acopio/" + ar[index] + ".pdf", "_blank");              
            }
            this.view = "table";
            this.mtd_search_filter();
            this.modal_escojido.modal_form = false;
            bus.$emit("alert", {
              color: "success",
              message: "ENDOSE GUARDADO CORRECTAMENTE!!",
            });
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    /*** aun no se usan*/
    mtd_export_excel: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          "GenerateEXCEL",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          this.download(response);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    // descarga el archivo
    download: function (data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "acopio.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    mtd_export_pdf: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/GeneratePDF",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          window.open("/assets/sales/" + response + ".pdf", "_blank");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    mtd_view_pdf: function (item) {
      window.open("/assets/acopio/" + item.uuid + ".pdf", "_blank");
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_edit: function (id) {
      this.$router.push({ path: "provideracopio/edit/" + id });
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id_acopio,
          id_detail: this.modal_delete.item.id_detalle_acopio
        },
      })
        .then((response) => {
          if (response.state == 0) {
            this.mtd_search_filter();
            bus.$emit("alert", {
              color: "success",
              message: "ELIMINADO CORRECTAMENTE",
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
          this.close_delete();
        })
        .catch((errors) => {
          bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });
        });
    },
  },
};
</script>